import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Chatbot = _resolveComponent("Chatbot")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("a", {
      class: "skip-link",
      ref: "skipLink",
      href: "#main"
    }, _toDisplayString(_ctx.$t("accessibility.skip")), 513),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$i18n.locale
    })),
    (_ctx.activeRequests !== 0)
      ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.chatbotEnabled)
      ? (_openBlock(), _createBlock(_component_Chatbot, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}