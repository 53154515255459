
import { defineComponent } from "vue";
import { getClient, WebchatClient } from "@botpress/webchat";
import { VueShowdown } from "vue-showdown";
import DOMPurify from "dompurify";

interface Message {
  text: string;
  type: string;
  sentOn: Date;
}

export default defineComponent({
  name: "Chatbot",
  data() {
    return {
      storageUrl: process.env.VUE_APP_STORAGE_URL,
      isChatOpen: false,
      isTyping: false,
      client: null as WebchatClient | null,
      messageText: "",
      messagesOutgoing: [] as Array<Message>,
      messagesIncoming: [] as Array<Message>,
    };
  },

  computed: {
    allMessages() {
      const messages = [
        ...this.messagesOutgoing,
        ...this.messagesIncoming,
      ] as Array<Message>;

      return messages.sort(
        (a, b) => new Date(a.sentOn).getTime() - new Date(b.sentOn).getTime(),
      );
    },
  },
  watch: {
    $data: {
      handler() {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      },
      deep: true,
    },
  },

  mounted() {
    this.initializeChat();
  },

  methods: {
    async scrollToBottom() {
      const container = document.querySelector(".webchat-container");

      if (this.isChatOpen && container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    async initializeChat() {
      this.client = getClient({
        clientId: process.env.VUE_APP_CHATBOT_CLIENT_ID,
      });

      this.client.on("message", (message) => {
        this.messagesIncoming.push({
          // @ts-expect-error Wrong type
          text: message.payload.block.text,
          type: "incoming",
          sentOn: new Date(),
        });

        this.isTyping = false;
      });

      this.client.on("isTyping", (isTyping) => {
        if (isTyping.isTyping) {
          this.isTyping = true;
        } else {
          this.isTyping = false;
        }
      });

      await this.client.connect();

      await this.client.sendEvent({
        type: "conversationStarted",
      });
    },
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
    async sendMessage() {
      if (this.messageText.trim() && this.client) {
        this.client.sendMessage({
          text: this.messageText.trim(),
          type: "text",
        });

        this.messagesOutgoing.push({
          text: this.messageText.trim(),
          type: "outgoing",
          sentOn: new Date(),
        });

        this.messageText = "";
      }
    },
    sanitizedMessage(text: string) {
      return DOMPurify.sanitize(text, {
        ALLOWED_TAGS: [
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "p",
          "span",
          "br",
          "strong",
          "em",
          "i",
          "ul",
          "ol",
          "li",
        ],
        ALLOWED_ATTR: [],
      });
    },
  },

  components: {
    VueShowdown,
  },
});
