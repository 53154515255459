import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5393c968"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "message"
}
const _hoisted_2 = { class: "wrapperMessage feedbackMessage" }
const _hoisted_3 = { class: "messageText" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "type notify" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "title" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "title" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "title" }
const _hoisted_16 = { class: "grid-images" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 0,
  class: "userText"
}
const _hoisted_20 = { class: "popover-content" }
const _hoisted_21 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.feedback)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("ul", _hoisted_5, [
                  _createElementVNode("li", null, [
                    _createElementVNode("label", null, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("feedback.time")) + ": ", 1),
                      (_ctx.feedback.time)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.$moment.utc(_ctx.feedback.time).local().format("DD.MM.YYYY")) + " " + _toDisplayString(_ctx.$t("feedback.time_at")) + " " + _toDisplayString(_ctx.$moment(_ctx.feedback.time).format("HH:mm")), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.$t("feedback.no_time")), 1)
                          ]))
                    ])
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("label", null, [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("feedback.title")) + ": ", 1),
                      (_ctx.feedback.title)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.feedback.title), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_11, [
                            _createElementVNode("strong", null, [
                              _createElementVNode("em", null, _toDisplayString(_ctx.$t("feedback.no_title")), 1)
                            ])
                          ]))
                    ])
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("label", null, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("feedback.category")) + ":", 1),
                      (_ctx.feedback.type)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.$t(`feedback.${_ctx.feedback.type.name}`)), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_14, [
                            _createElementVNode("strong", null, [
                              _createElementVNode("em", null, _toDisplayString(_ctx.$t("feedback.no_category")), 1)
                            ])
                          ]))
                    ])
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("label", null, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("feedback.local_community")) + ":", 1),
                      _createElementVNode("span", null, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.feedback.local_community?.name ??
                    _ctx.$t("feedback.all_local_communities")), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feedback.image_urls, (image, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "image-item",
                      onClick: ($event: any) => (_ctx.selectedImage = image.url)
                    }, [
                      _createElementVNode("img", {
                        src: image.url,
                        alt: ""
                      }, null, 8, _hoisted_18)
                    ], 8, _hoisted_17))
                  }), 128))
                ]),
                (_ctx.feedback.description)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.feedback.description), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedImage)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 1,
          to: "body"
        }, [
          _createElementVNode("div", {
            class: "image-popover",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedImage = null))
          }, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("img", {
                src: _ctx.selectedImage,
                alt: ""
              }, null, 8, _hoisted_21)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}