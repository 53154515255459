
import { defineComponent } from "vue";
import Loader from "@/components/customComponents/Loader.vue";
import {
  firebaseLogClickEvent,
  firebaseLogOutComeEvent,
} from "@/plugins/firebase";
import Chatbot from "@/components/Chatbot.vue";

export default defineComponent({
  name: "App",
  components: { Loader, Chatbot },
  created() {
    const locale = localStorage.getItem("locale");
    if (locale !== null) {
      this.$i18n.locale = locale;
      this.$moment.locale(locale);
    }
    document.title = this.$t("city.app_name");
  },
  data: () => ({
    chatbotEnabled: process.env.VUE_APP_CHATBOT_ENABLE === "true",
  }),
  computed: {
    activeRequests() {
      return this.$store.getters["loader/activeRequests"];
    },
  },
  mounted() {
    firebaseLogOutComeEvent("/home", "appOpen");
    document.addEventListener("click", this.handleButtonClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleButtonClick);
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("city.app_name");
    },
  },

  methods: {
    handleButtonClick(event: any) {
      const button = event.target.closest("button");

      if (button) {
        const buttonEventName = button.getAttribute("event-name");
        const buttonEventFrom = button.getAttribute("event-from");

        if (!buttonEventName || !buttonEventFrom) {
          return;
        }

        firebaseLogClickEvent(buttonEventFrom, buttonEventName);
      }
    },
  },
});
