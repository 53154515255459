
import { Route } from "@/typings/RoutePlanner";
import { defineComponent, PropType } from "vue";
import IconWalk from "~icons/tabler/walk";
import IconCar from "~icons/tabler/car";
import IconBike from "~icons/tabler/bike";
import IconChevronRight from "~icons/tabler/chevron-right";
import IconLock from "~icons/tabler/lock";
import IconBus from "~icons/tabler/bus";
import IconRail from "~icons/tabler/train";

export default defineComponent({
  components: {
    IconWalk,
    IconCar,
    IconBike,
    IconChevronRight,
    IconLock,
    IconBus,
    IconRail,
  },

  props: {
    route: {
      type: Object as PropType<Route>,
      required: true,
    },
  },

  computed: {
    icons() {
      return [
        {
          value: "walk",
          icon: IconWalk,
        },
        {
          value: "bicycle",
          icon: IconBike,
        },
        {
          value: "bicycle_rent",
          icon: IconBike,
        },
        {
          value: "car",
          icon: IconCar,
        },
        {
          value: "transit",
          icon: IconBus,
        },
        {
          value: "bus",
          icon: IconBus,
        },
        {
          value: "rail",
          icon: IconRail,
        },
      ];
    },
    timeModeSelection() {
      const eventType = this.$route.query.eventType;

      if (eventType === "arrival") {
        return "arrival";
      }

      return "departure";
    },
    hasBikeInfo() {
      return this.route.legs.find(
        (leg) => leg.start.bikeStation ?? leg.end.bikeStation,
      );
    },
    hasBusInfo() {
      return this.route.legs.find((leg) => leg.start.station);
    },
  },

  methods: {
    handleRouteSelection(route: Route) {
      this.$store.dispatch("route-planner/setRoute", route);
      this.$router.push({ name: "SingleRoutePlannerView" });
    },
    getDuration(route: Route): string {
      const duration = this.$moment.duration(
        this.$moment(route.endTime).diff(this.$moment(route.startTime)),
      );

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());

      return hours >= 1 ? `${hours}h ${minutes}min` : `${minutes}min`;
    },
    formatTime(time: string): string {
      return this.$moment(time).format("HH:mm");
    },
    getDepartureTime(route: Route): string {
      return this.$moment(route.startTime).format("HH:mm");
    },
    getArrivalTime(route: Route): string {
      return this.$moment(route.endTime).format("HH:mm");
    },
    getIconComponent(iconValue: string) {
      const icon = this.icons.find(
        (item) => item.value.toLowerCase() === iconValue.toLowerCase(),
      );
      return icon ? icon.icon : IconWalk;
    },
    bikeInfo() {
      return (
        this.hasBikeInfo?.start.bikeStation ?? this.hasBikeInfo?.end.bikeStation
      );
    },
  },
});
