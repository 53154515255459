import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex align-items-center gap-8 w-full lg:w-fit"
}
const _hoisted_2 = { class: "font-semibold text-center lg:hidden line-height-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_AddressSearch = _resolveComponent("AddressSearch")!
  const _component_Modes = _resolveComponent("Modes")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_TimeSelect = _resolveComponent("TimeSelect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-column lg:align-items-center gap-3 w-full lg:flex-row", {
      'bg-white p-3 border-round-2xl shadow-1 sm:px-5': !_ctx.hasRouteQuery,
    }])
  }, [
    (_ctx.hasRouteQuery)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Button, {
            class: "bg-transparent border-none text-900 items-center justify-center",
            onClick: _ctx.goBack
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent('IconChevronLeft')))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("route_planner.suggested_routes")), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_AddressSearch, {
      modelValue: _ctx.startLoc,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startLoc) = $event)),
      label: _ctx.$t('route_planner.start_location')
    }, {
      icon: _withCtx(() => [
        _createElementVNode("i", null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent('IconMapPin'), { class: "text-700" }))
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "label"]),
    _createVNode(_component_AddressSearch, {
      modelValue: _ctx.endLoc,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endLoc) = $event)),
      label: _ctx.$t('route_planner.end_location')
    }, {
      icon: _withCtx(() => [
        _createElementVNode("i", null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent('IconFlag'), { class: "text-700" }))
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "label"]),
    _createVNode(_component_Modes, {
      "active-modes": _ctx.modes,
      onSave: _ctx.updateModes
    }, null, 8, ["active-modes", "onSave"]),
    _createVNode(_component_Divider, {
      layout: "vertical",
      class: "hidden m-0 lg:block"
    }),
    _createVNode(_component_Divider, { class: "m-0 lg:hidden" }),
    _createVNode(_component_TimeSelect, {
      type: _ctx.eventType,
      date: _ctx.eventAt,
      onSave: _cache[2] || (_cache[2] = 
        (data) => {
          _ctx.eventType = data.eventType;
          _ctx.eventAt = data.eventAt;
        }
      )
    }, null, 8, ["type", "date"]),
    _createVNode(_component_Button, {
      class: "button primary w-full sm:w-fit",
      label: _ctx.$t('global.go'),
      onClick: _ctx.goToRouteView
    }, null, 8, ["label", "onClick"])
  ], 2))
}