import axios, { AxiosRequestHeaders } from "axios";
import VueAxios from "vue-axios";
import Qs from "qs";
import store from "./store";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    store.dispatch("loader/increaseActiveRequests");

    config.paramsSerializer = {
      serialize: (params) =>
        Qs.stringify(params, { arrayFormat: "brackets", encode: false }),
    };

    if (config.headers === undefined) {
      config.headers = {} as AxiosRequestHeaders;
    }
    if (store.getters["user/tokens"] !== null) {
      config.headers.Authorization =
        "Bearer " + store.getters["user/tokens"].access_token;
    }
    config.headers.Accept = "application/json";
    return config;
  },
  (error) => {
    store.dispatch("loader/decreaseActiveRequests");
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    store.dispatch("loader/decreaseActiveRequests");
    return response;
  },
  (error) => {
    store.dispatch("loader/decreaseActiveRequests");
    const originalRequest = error.config;

    const tokenRefreshNeeded =
      error.response.status === 401 &&
      error.response.config.url.indexOf("auth/login") === -1 &&
      error.response.config.url.indexOf("auth/refresh") === -1 &&
      error.response.config.url.indexOf("auth/logout") === -1;

    if (!tokenRefreshNeeded) {
      return Promise.reject(error);
    }

    return new Promise((resolve, reject) => {
      store.dispatch("user/refresh").then(
        () => {
          resolve(axios(originalRequest));
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
);

function loadAuthenticatedUser(): Promise<void> {
  return new Promise((resolve) => {
    const localStorageTokens = localStorage.getItem("tokens");

    if (localStorageTokens === null) {
      resolve();
      return;
    }
    store.commit("user/setTokens", JSON.parse(localStorageTokens));
    store
      .dispatch("user/userinfo")
      .catch(() => store.dispatch("user/logout"))
      .finally(() => {
        resolve();
      });
  });
}

export { axios, VueAxios, loadAuthenticatedUser };
