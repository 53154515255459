
import { defineComponent } from "vue";
import {
  UserTransation,
  TransactionData,
  TransactionSource,
  TransactionLocale,
} from "@/typings/Loyalty";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { Locale } from "vue-i18n";

export default defineComponent({
  data: () => ({
    transations: {} as UserTransation,
    isLoading: false,
  }),
  mounted() {
    this.loadTransations();
  },
  methods: {
    getTransactionInfo(
      source: TransactionSource,
      infoType: "name" | "description",
    ): string {
      const currentLocale = this.$i18n.locale as Locale;
      return (
        source.localizations[infoType][currentLocale as keyof TransactionLocale] ??
        source.localizations[infoType]["sl"]
      );
    },
    loadTransations(page = 1) {
      let params = {
        page,
        perPage: 10,
      };
      this.isLoading = true;
      this.$http
        .get("api/v1/user/" + this.user.id + "/transactions", { params })
        .then((response) => {
          if (!this.isLoading) {
            return;
          }
          if (page !== 1) {
            this.transations.data = this.transations.data.concat(
              response.data.data,
            );
            this.transations.meta.current_page =
              response.data.meta.current_page;
            return;
          }
          this.transations = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    translateName(transaction: TransactionData) {
      if (
        transaction.sourceType === "action" ||
        transaction.sourceType === "reward"
      ) {
        return this.getTransactionInfo(transaction.source, "name");
      }

      if (transaction.reason === "Bulk Update") {
        if (transaction.change > 0) {
          return this.$t("my_points.received_points");
        }

        return this.$t("my_points.taken_points");
      }

      if (transaction.sourceType === "transferedpoints") {
        if (transaction.change > 0) {
          return this.$t("my_points.received_points");
        }

        return this.$t("my_points.gifted_points");
      }
    },
    translateReason(transaction: TransactionData) {
      if (transaction.reason === "Bulk Update") {
        if (transaction.change > 0) {
          return this.$t("my_points.admin_recived_points_description");
        }

        return this.$t("my_points.admin_taken_points_description");
      }
    },
    translateDescription(transaction: TransactionData) {
      if (
        transaction.sourceType === "action" ||
        transaction.sourceType === "reward"
      ) {
        return this.getTransactionInfo(transaction.source, "description");
      }

      if (transaction.sourceType === "transferedpoints") {
        if (transaction.change > 0) {
          return this.$t("my_points.received_points_description");
        }

        return this.$t("my_points.gifted_points_description");
      }
    },
    getValuePrefix(value: number) {
      return value > 0 ? "+" + value : value;
    },
    getValueColors(value: number): string {
      return value > 0 ? "#0EA44D" : "#f05323";
    },
    isRewardOrAction(transaction: TransactionData) {
      return (
        transaction.sourceType === "reward" ||
        transaction.sourceType === "action"
      );
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: {
    InfinityScroll,
  },
});
