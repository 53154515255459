
import { defineComponent } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { RewardCategory } from "@/typings/RewardCategory";
import { Locale } from "vue-i18n";

export default defineComponent({
  data: () => ({
    categories: [] as RewardCategory[],
    selectedCategory: null as null | string,
    loyaltyPoints: 0,
  }),
  mounted() {
    this.loadRewardCategories();
    this.loadUserLoyaltyPoints();
  },
  methods: {
    isSelectedCategory(index: number) {
      const type = this.getCategoryName(index);

      return type === this.$route.query.type;
    },
    loadRewardCategories() {
      this.$http.get("api/v1/loyalty/reward-categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getCategoryLocale(rewardCategory: RewardCategory) {
      const currentLocale = this.locale;

      return (
        rewardCategory.localizations["name"][currentLocale as keyof Locale] ??
        rewardCategory.localizations["name"]["sl" as keyof Locale]
      );
    },
    setCategory(index: number | null) {
      if (index === null) {
        this.$router.push({
          name: "Rewards",
          query: {
            type: index,
          },
        });

        return;
      }

      this.$router.push({
        name: "Rewards",
        query: {
          type: this.getCategoryName(index),
        },
      });
    },
    loadUserLoyaltyPoints() {
      this.$http
        .get("api/v1/user/" + this.user.id + "/points")
        .then((response) => {
          this.loyaltyPoints = response.data.current;
        });
    },
    getCategoryName(index: number): string {
      const category = this.categories[index].localizations;
      const name = category["name"];

      return this.locale === "en"
        ? String(name["sl" as keyof Locale])
        : String(name[this.locale as keyof Locale]);
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    locale(): Locale {
      return this.$i18n.locale;
    },
    localizeCategories(): RewardCategory[] {
      return this.categories.map((category: RewardCategory) => {
        return {
          id: category.id,
          name: this.getCategoryLocale(category),
          color: category.color,
        } as RewardCategory;
      });
    },
  },
  components: { Accordion, AccordionTab },
});
