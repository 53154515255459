
import { defineComponent } from "vue";
import { FeedbackItem } from "@/typings/FeedbackItem";

export default defineComponent({
  props: {
    feedback: Object as () => FeedbackItem,
  },
  data: () => ({
    selectedImage: null as string | null,
  }),
});
