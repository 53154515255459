
import { WeatherForecast } from "@/typings/RoutePlanner";
import { defineComponent, PropType } from "vue";
import IconAlertOctagon from "~icons/tabler/alert-octagon";
import IconChevronRight from "~icons/tabler/chevron-right";
import Button from "primevue/button";

export default defineComponent({
  components: {
    IconAlertOctagon,
    IconChevronRight,
    Button,
  },

  props: {
    weatherForecast: {
      type: Array as PropType<WeatherForecast[]>,
      required: true,
    },
  },

  computed: {
    rainWarning() {
      const eventAtQuery = this.$route.query.eventAt as string;
      const eventTime = new Date(eventAtQuery);
      const twoHoursLater = new Date(eventTime.getTime() + 2 * 60 * 60 * 1000);

      const hasWarning = this.weatherForecast.some((forecast) => {
        const forecastTime = new Date(forecast.time);

        return (
          forecast.clouds.warning === true &&
          forecastTime >= eventTime &&
          forecastTime <= twoHoursLater
        );
      });

      return hasWarning;
    },
    warningText() {
      return this.$t("route_planner.forecast.possible");
    },
  },
});
